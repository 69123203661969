var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Basic", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Rendering basic Datetime Picker with "),
        _c("code", [_vm._v("v-model")]),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("flat-pickr", {
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _c("p", { staticClass: "mt-4" }, [
            _vm._v("Selected Date: " + _vm._s(_vm.date)),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <flat-pickr v-model=\"date\" />\n</template>\n\n<script>\nimport flatPickr from 'vue-flatpickr-component';\nimport 'flatpickr/dist/flatpickr.css';\n\nexport default {\n    data() {\n        return {\n            date: null,\n        }\n    },\n    components: {\n        flatPickr\n    }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }