var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Change Locale", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Import locale and set "),
        _c("code", [_vm._v("locale")]),
        _vm._v(" in config of picker to change locale."),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("flat-pickr", {
            attrs: {
              config: _vm.configdateTimePicker,
              placeholder: "Choose Date",
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
          _c("p", { staticClass: "mt-4" }, [
            _vm._v("Selected Date: " + _vm._s(_vm.date)),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <flat-pickr :config=\"configdateTimePicker\" v-model=\"date\" placeholder=\"Choose Date\" />\n</template>\n\n<script>\nimport flatPickr from 'vue-flatpickr-component'\nimport 'flatpickr/dist/flatpickr.min.css';\nimport {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js';\n\nexport default {\n    data() {\n        return {\n            date: null,\n            configdateTimePicker: {\n              locale: FrenchLocale\n            }\n        }\n    },\n    components: {\n        flatPickr\n    }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }