var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Datetime Picker", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Rendering datetime picker by updating config")]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("flat-pickr", {
            attrs: {
              config: _vm.configdateTimePicker,
              placeholder: "Date Time",
            },
            model: {
              value: _vm.datetime,
              callback: function ($$v) {
                _vm.datetime = $$v
              },
              expression: "datetime",
            },
          }),
          _c("p", { staticClass: "mt-4" }, [
            _vm._v("Selected Datetime: " + _vm._s(_vm.datetime)),
          ]),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          "\n<template>\n  <flat-pickr :config=\"configdateTimePicker\" v-model=\"datetime\" placeholder=\"Date Time\" />\n</template>\n\n<script>\nimport flatPickr from 'vue-flatpickr-component';\nimport 'flatpickr/dist/flatpickr.css';\n\nexport default {\n    data() {\n        return {\n            datetime: null,\n            configdateTimePicker: {\n              enableTime: true,\n              dateFormat: 'd-m-Y H:i'\n            }\n        }\n    },\n    components: {\n        flatPickr\n    }\n}\n</script>\n        "
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }