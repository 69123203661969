var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Start-End Date", "code-toggler": "" } },
    [
      _c("p", [_vm._v("Create start and end date - date picker")]),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "div",
          [
            _c("flat-pickr", {
              attrs: {
                config: _vm.configFromdateTimePicker,
                placeholder: "From Date",
              },
              on: { "on-change": _vm.onFromChange },
              model: {
                value: _vm.fromDate,
                callback: function ($$v) {
                  _vm.fromDate = $$v
                },
                expression: "fromDate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("flat-pickr", {
              attrs: {
                config: _vm.configTodateTimePicker,
                placeholder: "To Date",
              },
              on: { "on-change": _vm.onToChange },
              model: {
                value: _vm.toDate,
                callback: function ($$v) {
                  _vm.toDate = $$v
                },
                expression: "toDate",
              },
            }),
          ],
          1
        ),
      ]),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <flat-pickr :config="configFromdateTimePicker" v-model="fromDate" placeholder="From Date" @on-change="onFromChange" />\n  <flat-pickr :config="configTodateTimePicker" v-model="toDate" placeholder="To Date" @on-change="onToChange" />\n</template>\n\n<script>\nimport flatPickr from \'vue-flatpickr-component\';\nimport \'flatpickr/dist/flatpickr.css\';\n\nexport default {\n    data() {\n        return {\n            fromDate: null,\n            toDate: null,\n            configFromdateTimePicker: {\n              minDate: new Date(),\n              maxDate: null\n            },\n            configTodateTimePicker: {\n              minDate: null\n            }\n        }\n    },\n    methods: {\n      onFromChange(selectedDates, dateStr, instance) {\n        this.$set(this.configTodateTimePicker, \'minDate\', dateStr);\n      },\n      onToChange(selectedDates, dateStr, instance) {\n        this.$set(this.configFromdateTimePicker, \'maxDate\', dateStr);\n      }\n    },\n    components: {\n        flatPickr\n    }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }