var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Allow input in Picker", "code-toggler": "" } },
    [
      _c("p", [
        _vm._v("Use "),
        _c("code", [_vm._v("allowInput")]),
        _vm._v(" to create Datepicker with input support"),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c("flat-pickr", {
            attrs: {
              config: _vm.configdateTimePicker,
              placeholder: "Insert Date",
            },
            model: {
              value: _vm.date,
              callback: function ($$v) {
                _vm.date = $$v
              },
              expression: "date",
            },
          }),
        ],
        1
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <flat-pickr :config="configdateTimePicker" v-model="date" placeholder="Inline Picker" />\n</template>\n\n<script>\nimport flatPickr from \'vue-flatpickr-component\';\nimport \'flatpickr/dist/flatpickr.css\';\n\nexport default {\n    data() {\n        return {\n            date: null,\n            configdateTimePicker: {\n              inline: true,\n            }\n        }\n    },\n    components: {\n        flatPickr\n    }\n}\n</script>\n        '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }